import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { PortfolioItem } from "./portfolioItem";
import { Paragraph } from "./paragraph";
import { Heading } from "./heading";
import { Section } from "./section";
import { Row, Column } from "./grid";

const Review = styled(Paragraph)`
  margin-top: 16px;
  font-style: italic;
`;

const ReviewAuthor = styled(Review)`
  font-weight: bold;
  margin-top: 0;
  &:before {
    content: "~";
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
`;

export function Portfolio({ heading, items, hash }) {
  return (
    <Section id={hash}>
      <Row>
        <Column xs={12} alignItems="center">
          <Heading>{heading}</Heading>
        </Column>
      </Row>
      <Row justifyContent="center">
        {items.map(testimonial => {
          return (
            <Column key={testimonial.id} xs={12} md={6} lg={4}>
              <Row>
                <StyledLink to={testimonial.url}>
                  <PortfolioItem imageUrl={testimonial.imageUrl} />
                </StyledLink>
              </Row>
              <Row>
                <Review>{testimonial.description}</Review>
              </Row>
              <Row>
                <ReviewAuthor>{testimonial.author}</ReviewAuthor>
              </Row>
            </Column>
          );
        })}
      </Row>
    </Section>
  );
}
