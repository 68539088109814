import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 16px;
  padding: 0 4px;
  font-size: 16px;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 2px solid #152720;
  background-color: #fff;
  outline: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: 0.3s ease all;

  &::placeholder {
    color: #666666;
  }

  &:disabled {
    border-bottom: 2px solid #666666;
  }

  &:focus {
    border-color: #bc649d;
  }
`;

export const InputLabel = styled.label`
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 4px;
  margin-left: 4px;
  color: ${({ disabled }) => (disabled ? "#000" : "inherit")};
`;

const TextareaBase = Input.withComponent("textarea");

export const TextArea = styled(TextareaBase)`
  resize: vertical;
  min-height: 150px;
  padding: 15px;
`;
