import React from "react";
import styled from "styled-components";

import { Heart } from "./heart";
import logo from "../images/weselove-logo.svg";
import { useLogoAnimation } from "./useLogoAnimation";
import { Row, BREAKPOINTS } from "./grid";

const Logo = styled.img`
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 120px;
  }
`;

const HeartRow = styled(Row)`
  margin: 100px auto 0 auto;
  max-width: 620px;
  padding: 0 16px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    margin: 130px auto 0 auto;
  }
`;

export function Hero() {
  const { opacity: logoOpacity, scale: logoScale } = useLogoAnimation();

  return (
    <>
      <Logo
        src={logo}
        style={{
          opacity: logoOpacity,
          transform: `translateX(-50%) scale3d(${logoScale}, ${logoScale}, ${logoScale})`,
        }}
      />
      <HeartRow>
        <Heart />
      </HeartRow>
    </>
  );
}
