function normalizeContentfulData(rawData) {
  let result = {};

  Object.keys(rawData).forEach(key => {
    const edges = rawData[key].edges;

    edges.forEach(edge => {
      const language = edge.node.node_locale;

      if (!language) {
        throw new Error("Missing node_locale property in query");
      }

      if (result[language] === undefined) {
        result[language] = {};
      }

      if (result[language][key] === undefined) {
        result[language][key] = [];
      }

      result[language][key] = [...result[language][key], edge.node];
    }, {});
  });

  return result;
}

exports.normalizeContentfulData = normalizeContentfulData;
