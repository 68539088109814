import React from "react";
import styled, { keyframes } from "styled-components";

import { Triangle } from "./heartTriangles";
import { heartShapeConfig } from "./heartShapeConfig";

const heartbeat = keyframes`
  0% { transform: scale(1) }
  15% { transform: scale(1) }
  20% { transform: scale(1.006) }
  25% { transform: scale(1) }
  30% { transform: scale(1.004) }
  35% { transform: scale(1) }
`;

const Grid = styled.div`
  width: 100%;
  animation: ${heartbeat} 2.5s ease infinite;
`;

const GridRow = styled.div`
  display: flex;
`;

const GridCell = styled.div`
  position: relative;
  width: 25%;
  padding-top: 25%;
  margin: 1px 4px;
`;

function getFormattedArray(arr, cellsPerRow) {
  let formatted = [];
  let rows = [];
  let cells = [];

  for (let i = 0, len = arr.length; i < len; i++) {
    cells.push(arr[i]);

    if (cells.length === 2) {
      rows.push(cells);
      cells = [];
    }

    if (rows.length === cellsPerRow) {
      formatted.push(rows);
      rows = [];
    }
  }

  return formatted;
}

export function Heart() {
  const grid = getFormattedArray(heartShapeConfig, 4);

  return (
    <Grid>
      {grid.map((row, index) => {
        return (
          <GridRow key={index}>
            {row.map((cell, index) => {
              return (
                <GridCell key={index}>
                  {cell.map(triangle => {
                    return <Triangle key={triangle.id} {...triangle} />;
                  })}
                </GridCell>
              );
            })}
          </GridRow>
        );
      })}
    </Grid>
  );
}
