import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { Section } from "./section";
import { Row, Column, BREAKPOINTS } from "./grid";
import { Input, InputLabel, TextArea } from "./inputs";
import { Button } from "./button";
import { useLanguage } from "./useLanguage";
import { Heading } from "./heading";
import { Divider } from "./divider";
import { useScreenInfo } from "./useScreenInfo";
import { Checkbox } from "./checkbox";

import smartphoneIcon from "../images/smartphone.svg";
import emailIcon from "../images/email.svg";
import { renderRichText } from "../richTextRenderer";
import { translate } from "../translate";

const ContactLink = styled.a`
  text-decoration: none;
  color: #000;
  font-weight: bold;
  margin-top: 8px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const HiddenInfoLink = styled.div`
  text-decoration: none;
  color: #000;
  font-weight: bold;
  margin-top: 8px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const HiddenInfo = styled.div`
  text-decoration: none;
  color: #000;
  font-weight: bold;
  margin-top: 8px;
  white-space: nowrap;
`;

const ContactItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
  width: 150px;
`;

const DimmedText = styled.span`
  opacity: 0.3;
`;

const FormHolder = styled(Row)`
  margin-top: 64px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    margin-top: 24px;
  }
`;

const IconContainer = styled.div`
  height: 45px;
`;

export function Contact({ heading, content, hash, contactDetails }) {
  const [checked, setChecked] = useState(false);
  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [isEmailShown, setIsEmailShown] = useState(false);
  const [isPhoneShown, setIsPhoneShown] = useState(false);
  const { isLargeUp } = useScreenInfo();
  const { language } = useLanguage();
  const { mail, phone, formConsent } = contactDetails;
  const reCaptchaElement = useRef(null);

  function handleCheckboxChange() {
    setChecked(prevChecked => !prevChecked);
  }

  function handleRecaptchaChange() {
    if (reCaptchaElement.current.getValue() !== "") {
      setRecaptchaChecked(true);
    }
  }

  function handleShowPhone() {
    setIsPhoneShown(true);
  }

  function handleShowEmail() {
    setIsEmailShown(true);
  }

  function encodeEmail(email) {
    let encodedEmail = "";
    for (let i = 0; i < email.length; i++) {
      encodedEmail += "&#" + email.charCodeAt(i) + ";";
    }
    return encodedEmail;
  }

  return (
    <Section id={hash}>
      <Row>
        <Column xs={12} alignItems="center">
          <Heading>{heading}</Heading>
        </Column>
      </Row>
      <Row alignItems="center">
        <Column lg={6} xs={12}>
          {renderRichText(content.json)}
        </Column>
        <Column
          lg={6}
          xs={12}
          flexDirection="row"
          justifyContent="space-around"
        >
          {isLargeUp && <Divider />}
          <ContactItemHolder>
            <IconContainer>
              <img
                src={smartphoneIcon}
                alt={translate("smartphoneIcon", language)}
              />
            </IconContainer>
            {isPhoneShown ? (
              <ContactLink href={`tel:${phone}`}>{phone}</ContactLink>
            ) : (
              <HiddenInfoLink onClick={handleShowPhone}>
                {translate("showPhone", language)}
              </HiddenInfoLink>
            )}
          </ContactItemHolder>
          <ContactItemHolder>
            <IconContainer>
              <img src={emailIcon} alt={translate("emailIcon", language)} />
            </IconContainer>
            {isEmailShown ? (
              <HiddenInfo>{mail.replace("@", "[at]")}</HiddenInfo>
            ) : (
              <HiddenInfoLink onClick={handleShowEmail}>
                {translate("showEmail", language)}
              </HiddenInfoLink>
            )}
          </ContactItemHolder>
        </Column>
      </Row>
      <form
        action="https://formspree.io/f/xyybozdp"
        method="POST"
        data-netlify-recaptcha="true"
        data-netlify="true"
      >
        <FormHolder>
          <Column lg={6} xs={12}>
            <InputLabel>Email</InputLabel>
            <Input
              id="email"
              placeholder={translate("emailPlaceholder", language)}
              type="text"
              name="_replyto"
            />
          </Column>
          <Column lg={6} xs={12}>
            <InputLabel>
              {translate("name", language)}:{" "}
              <DimmedText>({translate("optionally", language)})</DimmedText>
            </InputLabel>
            <Input
              id="name"
              placeholder={translate("namePlaceholder", language)}
              type="text"
              name="name"
            />
          </Column>
          <Column xs={12}>
            <InputLabel>{translate("message", language)}</InputLabel>
            <TextArea
              id="content"
              placeholder={translate("whatDoYouWantToAsk", language)}
              type="text"
              name="content"
            />
          </Column>
          <Column sm={12}>
            <Checkbox
              id="form-checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
            >
              {documentToReactComponents(formConsent.json)}
            </Checkbox>
            <ReCAPTCHA
              ref={reCaptchaElement}
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
              onChange={handleRecaptchaChange}
            />
          </Column>
          <Column xs={12}>
            <Button type="submit" disabled={!checked || !recaptchaChecked}>
              {translate("send", language)}
            </Button>
          </Column>
        </FormHolder>
        <input type="hidden" name="_language" value="pl" />
      </form>
    </Section>
  );
}
