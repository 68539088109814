import React from "react";
import styled from "styled-components";

import { Section } from "./section";
import { Row, Column, BREAKPOINTS } from "./grid";
import { triangles } from "./triangles";
import { useScreenInfo } from "./useScreenInfo";

import aboutPhoto from "../images/about-photo.jpg";
import aboutPhotoTriangle from "../images/about-photo-triangle.png";
import { renderRichText } from "../richTextRenderer";
import { Heading } from "./heading";

const AboutPhoto = styled.img`
  display: block;
  width: 100%;
  max-width: 500px;
`;

const TriangleHolder = styled.div`
  display: block;
  position: absolute;
  opacity: 0.35;
  z-index: -1;
  transform: translate(-100px, -130px);
  min-width: 750px;
  color: #ffffff;

  @media (max-width: ${BREAKPOINTS.md}px) {
    transform: translate(100px, -200px) scale(1.1);
  }
`;

export function About({ heading, content, hash }) {
  const { isMediumDown } = useScreenInfo();

  return (
    <Section id={hash}>
      <Row>
        <Column xs={12} alignItems="center">
          <Heading>{heading}</Heading>
        </Column>
      </Row>
      <Row>
        <Column
          xs={12}
          lg={6}
          alignItems={isMediumDown ? "center" : "flex-end"}
        >
          <AboutPhoto src={isMediumDown ? aboutPhoto : aboutPhotoTriangle} />
        </Column>
        <Column
          xs={12}
          lg={6}
          alignItems={isMediumDown ? "center" : "flex-start"}
        >
          {!isMediumDown && <TriangleHolder>{triangles.bl.svg}</TriangleHolder>}

          {renderRichText(content.json)}
        </Column>
      </Row>
    </Section>
  );
}
