// br - bottom right
// bl - bottom left
// tr - top right
// tl - top left

import bl1 from "../../images/bl-1.png";
import bl2 from "../../images/bl-2.png";
import bl3 from "../../images/bl-3.png";
import br1 from "../../images/br-1.png";
import tr1 from "../../images/tr-1.png";
import tr2 from "../../images/tr-2.png";
import tl1 from "../../images/tl-1.png";
import tl2 from "../../images/tl-2.png";
import tl3 from "../../images/tl-3.png";

const COLORS = {
  lightPink: "#F3DCEB",
  pink: "#9C5D74",
  brown: "#C3B3A0",
  navy: "#1B2934",
  lightGray: "#D2D1D1",
  gray: "#A3A19F",
  darkGreen: "#152720",
  green: "#677858",
  lightGreen: "#849A8C",
};

export const ALL_COLORS = Object.values(COLORS);

export const heartShapeConfig = [
  // ROW 1
  { id: 0, position: "br", initialColor: COLORS.lightPink },
  { id: 1, position: "tl" },

  { id: 2, position: "bl", photo: bl1 },
  { id: 3, position: "tr" },

  { id: 4, position: "br", initialColor: COLORS.brown },
  { id: 5, position: "tl" },

  { id: 6, position: "bl", photo: bl2 },
  { id: 7, position: "tr" },

  // ROW 2
  { id: 8, position: "tr", photo: tr1 },
  { id: 9, position: "bl", initialColor: COLORS.navy },

  { id: 10, position: "br", photo: br1 },
  { id: 11, position: "tl", initialColor: COLORS.lightGray },

  { id: 12, position: "tr", photo: tr2 },
  { id: 13, position: "bl", initialColor: COLORS.darkGreen },

  { id: 14, position: "br", initialColor: COLORS.gray },
  { id: 15, position: "tl", initialColor: COLORS.pink },

  // ROW 3
  { id: 16, position: "tr", initialColor: COLORS.green },
  { id: 17, position: "bl" },

  { id: 18, position: "tr", initialColor: COLORS.lightGreen },
  { id: 19, position: "bl", photo: bl3 },

  { id: 20, position: "tl", photo: tl1 },
  { id: 21, position: "br", initialColor: COLORS.lightPink },

  { id: 22, position: "tl", photo: tl2 },
  { id: 23, position: "br" },

  // ROW 4
  { id: 24, position: "tl" },
  { id: 25, position: "br" },

  { id: 26, position: "tr", initialColor: COLORS.gray },
  { id: 27, position: "bl" },

  { id: 28, position: "tl", photo: tl3 },
  { id: 29, position: "br" },

  { id: 30, position: "tl" },
  { id: 31, position: "br" },
];
