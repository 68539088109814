import React from "react";
import styled from "styled-components";

import { triangles } from "./triangles";
import { IconArrowRight } from "./icons/iconArrowRight";

const Icon = styled(IconArrowRight)`
  position: absolute;
  color: #000;
  bottom: 35px;
  right: 30px;
  transition: 0.2s ease right;
`;

const PortfolioButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  height: 130px;
  width: 130px;
`;

const PortfolioItemHolder = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    transition: 0.2s ease all;

    & ${Icon} {
      right: 22px;
    }
  }
`;

const Image = styled.div`
  display: block;
  min-height: 250px;
  width: 100%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  background-color: #cccccc;
`;

export function PortfolioItem({ imageUrl, title = "" }) {
  return (
    <PortfolioItemHolder>
      <Image url={imageUrl} title={title} />
      <PortfolioButton>
        {triangles.br.svg} <Icon />
      </PortfolioButton>
    </PortfolioItemHolder>
  );
}
