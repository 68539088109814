import { useState, useEffect } from "react";

export function useColorAnimation({ initialColor, colors, timeout }) {
  const [color, setColor] = useState(initialColor);

  useEffect(() => {
    let _timeout = null;

    _timeout = setTimeout(() => {
      const filteredColors = colors.filter(c => c !== color);

      const newColor =
        filteredColors[Math.floor(Math.random() * filteredColors.length)];

      setColor(newColor);
    }, timeout || getRandomTimeout());

    return () => {
      clearTimeout(_timeout);
    };
  }, [color, colors, timeout]);

  return {
    color,
  };
}

function getRandomTimeout() {
  return Math.floor(Math.random() * 15) * 1000 + 1000;
}
