import React from "react";

const OFFSET = 2;

export const triangles = {
  br: {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
      >
        <path
          id="bottomRight"
          data-name="bottomRight"
          d="M432,489.089,633.088,288V489.089Z"
          transform="translate(-432 -288)"
          fill="currentColor"
        />
      </svg>
    ),
    top: `${OFFSET}px`,
    left: `${OFFSET}px`,
  },
  bl: {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
      >
        <path
          id="bottomLeft"
          data-name="bottomLeft"
          d="M432,489.089,633.088,288V489.089Z"
          transform="translate(489.089 -432) rotate(90)"
          fill="currentColor"
        />
      </svg>
    ),
    top: `${OFFSET}px`,
    left: `-${OFFSET}px`,
  },
  tl: {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
      >
        <path
          id="topRight"
          data-name="topRight"
          d="M432,489.089,633.088,288V489.089Z"
          transform="translate(633.088 489.089) rotate(180)"
          fill="currentColor"
        />
      </svg>
    ),

    top: `-${OFFSET}px`,
    left: `-${OFFSET}px`,
  },
  tr: {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
      >
        <path
          id="topLeft"
          data-name="topLeft"
          d="M432,489.089,633.088,288V489.089Z"
          transform="translate(-288 633.088) rotate(-90)"
          fill="currentColor"
        />
      </svg>
    ),
    top: `-${OFFSET}px`,
    left: `${OFFSET}px`,
  },
};
