import React from "react";
import styled from "styled-components";

import { useColorAnimation } from "../useColorAnimation";
import { triangles } from "../triangles";

import { ALL_COLORS } from "./heartShapeConfig";

const TriangleHolder = styled.div`
  position: absolute;
  top: ${({ position }) => triangles[position].top};
  left: ${({ position }) => triangles[position].left};
  width: 100%;
  height: 100%;
  color: ${({ color }) => color || "inherit"};
  transition: 3s ease color, 0.5s ease transform;
`;

const Photo = styled.img`
  display: block;
  width: 100%;
  margin: 5px 0 0 0;
`;

export function Triangle({ initialColor, photo, position }) {
  if (photo) return <PhotoTriangle photo={photo} position={position} />;

  if (!initialColor) return <TriangleInvisible position={position} />;

  return <TriangleAnimated initialColor={initialColor} position={position} />;
}

function TriangleAnimated({ initialColor, position }) {
  const { color } = useColorAnimation({
    initialColor,
    colors: ALL_COLORS,
  });

  return (
    <TriangleHolder color={color} position={position}>
      {triangles[position].svg}
    </TriangleHolder>
  );
}

function TriangleInvisible({ position = "br" }) {
  return (
    <TriangleHolder color="transparent" position={position}>
      {triangles[position].svg}
    </TriangleHolder>
  );
}

function PhotoTriangle({ photo, position = "br" }) {
  return (
    <TriangleHolder position={position} name="PhotoTriangle">
      <Photo src={photo} />
    </TriangleHolder>
  );
}
