import React from "react";
import styled from "styled-components";

import { IconCheckMark } from "./icons/iconCheckMark";

const SIZE = 25;

const Icon = styled(IconCheckMark)``;

const Label = styled.label`
  overflow: visible;
  min-height: ${SIZE}px;
  display: inline-flex;
`;

const IconHolder = styled.div`
  background: #ffffff;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border: 2px solid #bc649d;
  border-radius: 4px;
  fill: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  margin: 0;
  width: ${SIZE}px;
  height: ${SIZE}px;
  z-index: 1;
  cursor: pointer;
`;

const CheckboxText = styled.div`
  margin-left: 16px;
  font-size: 14px;
`;

export function Checkbox({ id, checked, children, onChange }) {
  return (
    <Label htmlFor={id}>
      <Input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <IconHolder aria-hidden="true">{checked && <Icon />}</IconHolder>
      <CheckboxText>{children}</CheckboxText>
    </Label>
  );
}
