import { useStaticQuery, graphql } from "gatsby";

import { useLanguage } from "./useLanguage";
import { normalizeContentfulData } from "../normalizeContentfulData";
import { LANGUAGE_MAPPER } from "../languageMapper";

const URLS = {
  pl: {
    portfolio: "/realizacje",
    offer: "/oferta",
  },
  en: {
    portfolio: "/en/portfolio",
    offer: "/en/offer",
  },
};

function hyphenate(string) {
  return string
    .toLowerCase()
    .split(" ")
    .join("-");
}

export function useHomepageData() {
  const { language } = useLanguage();

  const queryResult = useStaticQuery(graphql`
    query {
      allContentfulSection {
        edges {
          node {
            id
            node_locale
            sectionId
            heading
            content {
              id
              json
            }
          }
        }
      }
      allContentfulPortfolio {
        edges {
          node {
            id
            node_locale
            title
            url
            content {
              content
            }
            testimonial {
              id
              testimonial
            }
            testimonialImage {
              file {
                url
              }
            }
            testimonialAuthor
          }
        }
      }
      allContentfulOffer {
        edges {
          node {
            id
            uniqueId
            node_locale
            url
            title
            hasMoreButton
            description {
              json
            }
            offerItems {
              description {
                json
              }
            }
          }
        }
      }
      allContentfulContact {
        edges {
          node {
            id
            node_locale
            phone
            mail
            formConsent {
              json
            }
          }
        }
      }
    }
  `);

  const languageCode = LANGUAGE_MAPPER[language];
  const normalizedData = normalizeContentfulData(queryResult);
  const data = normalizedData[languageCode];

  const about = data.allContentfulSection.find(
    ({ sectionId }) => sectionId === "about"
  );
  const offer = data.allContentfulSection.find(
    ({ sectionId }) => sectionId === "offer"
  );
  const contact = data.allContentfulSection.find(
    ({ sectionId }) => sectionId === "contact"
  );
  const portfolio = data.allContentfulSection.find(
    ({ sectionId }) => sectionId === "portfolio"
  );

  const menu = {
    about: {
      sectionId: about.sectionId,
      label: about.heading.toLowerCase(),
      hash: `${hyphenate(about.heading)}`,
    },
    offer: {
      sectionId: offer.sectionId,
      label: offer.heading.toLowerCase(),
      hash: `${hyphenate(offer.heading)}`,
    },
    portfolio: {
      sectionId: portfolio.sectionId,
      label: portfolio.heading.toLowerCase(),
      hash: `${hyphenate(portfolio.heading)}`,
    },
    contact: {
      sectionId: contact.sectionId,
      label: contact.heading.toLowerCase(),
      hash: `${hyphenate(contact.heading)}`,
    },
    get list() {
      return [this.about, this.offer, this.portfolio, this.contact];
    },
  };

  const portfolioTestimonials = data.allContentfulPortfolio.map(item => {
    return {
      id: item.testimonial.id,
      description: item.testimonial.testimonial,
      author: item.testimonialAuthor,
      url: `${URLS[language].portfolio}/${item.url}`,
      imageUrl:
        item &&
        item.testimonialImage &&
        item.testimonialImage.file &&
        item.testimonialImage.file.url,
    };
  });

  const offerItems = data.allContentfulOffer.map(item => ({
    ...item,
    url: `${URLS[language].offer}/${item.url}`,
  }));

  const contactDetails = data.allContentfulContact[0] || [];

  return {
    about,
    offer,
    contact,
    portfolio,
    portfolioTestimonials,
    menu,
    offerItems,
    contactDetails,
  };
}
