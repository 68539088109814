import React from "react";

import { Layout } from "./layout";
import SEO from "./seo";
import { useLanguage } from "./useLanguage";
import { useHomepageData } from "./useHomepageData";

import { Hero } from "./hero";
import { About } from "./about";
import { Offer } from "./offer";
import { Portfolio } from "./portfolio";
import { Contact } from "./contact";

export function HomePage() {
  const { language } = useLanguage();
  const data = useHomepageData({ language });

  return (
    <Layout menu={data.menu}>
      <SEO title="Home" />
      <Hero />
      <About hash={data.menu.about.hash} {...data.about} />
      <Offer
        hash={data.menu.offer.hash}
        items={data.offerItems}
        {...data.offer}
      />
      <Portfolio
        hash={data.menu.portfolio.hash}
        items={data.portfolioTestimonials}
        {...data.portfolio}
      />
      <Contact
        hash={data.menu.contact.hash}
        contactDetails={data.contactDetails}
        {...data.contact}
      />
    </Layout>
  );
}
